var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "auth-wrapper auth-v1 px-2" }, [
    _c(
      "div",
      { staticClass: "auth-inner py-2" },
      [
        _c(
          "b-card",
          { staticClass: "mb-0" },
          [
            _c(
              "b-link",
              { staticClass: "brand-logo" },
              [
                _c("vuexy-logo"),
                _c("h2", { staticClass: "brand-text text-primary ml-1" }, [
                  _vm._v(" 닥터인포 관리자 "),
                ]),
              ],
              1
            ),
            _c("b-card-title", { staticClass: "mb-1" }, [
              _vm._v(" 닥터인포 관리자 페이지 입니다. "),
            ]),
            _c("b-card-text", { staticClass: "mb-2" }, [
              _vm._v(" 사내에서만 접속 가능하도록 제한되어 있습니다."),
              _c("br"),
              _vm._v(" 등록된 사용자는 UBWare 계정을 통해 로그인 가능합니다. "),
            ]),
            _c("validation-observer", {
              ref: "loginForm",
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var invalid = ref.invalid
                    return [
                      _c(
                        "b-form",
                        {
                          staticClass: "auth-login-form mt-2",
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.login($event)
                            },
                          },
                        },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { "label-for": "Id", label: "Id" } },
                            [
                              _c("validation-provider", {
                                attrs: { name: "Id", rules: "required" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("b-form-input", {
                                            attrs: {
                                              id: "Id",
                                              name: "login-Id",
                                              state:
                                                errors.length > 0
                                                  ? false
                                                  : null,
                                              placeholder: "유비웨어 계정 입력",
                                              autofocus: "",
                                            },
                                            model: {
                                              value: _vm.userId,
                                              callback: function ($$v) {
                                                _vm.userId = $$v
                                              },
                                              expression: "userId",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex justify-content-between",
                                },
                                [
                                  _c("label", { attrs: { for: "password" } }, [
                                    _vm._v("Password"),
                                  ]),
                                ]
                              ),
                              _c("validation-provider", {
                                attrs: { name: "Password", rules: "required" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c(
                                            "b-input-group",
                                            {
                                              staticClass: "input-group-merge",
                                              class:
                                                errors.length > 0
                                                  ? "is-invalid"
                                                  : null,
                                            },
                                            [
                                              _c("b-form-input", {
                                                staticClass:
                                                  "form-control-merge",
                                                attrs: {
                                                  id: "password",
                                                  type: "password",
                                                  state:
                                                    errors.length > 0
                                                      ? false
                                                      : null,
                                                  name: "login-password",
                                                  placeholder: "Password",
                                                },
                                                model: {
                                                  value: _vm.password,
                                                  callback: function ($$v) {
                                                    _vm.password = $$v
                                                  },
                                                  expression: "password",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-button",
                            {
                              staticStyle: { "margin-bottom": "15px" },
                              attrs: {
                                variant: "primary",
                                type: "submit",
                                block: "",
                                disabled: invalid,
                              },
                            },
                            [_vm._v(" Sign in ")]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }